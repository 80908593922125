import { Provider } from 'react-redux'
import { AppRoot, MarketingConfirmationContainer } from './components'
import { store } from './store'

export function MarketingConfirmationPage() {
  return (
    <Provider store={store}>
      <AppRoot>
        <MarketingConfirmationContainer />
      </AppRoot>
    </Provider>
  )
}
