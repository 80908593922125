import { type PropsWithChildren, useMemo } from 'react'
import { Locale } from '@sevenrooms/core/locales'
import { Root, logoFooterMessages } from '@sevenrooms/core/ui-kit/layout'
import { useStoreSelector } from '../store'

export function AppRoot({ children }: PropsWithChildren<{}>) {
  const { venueColor, locale } = useStoreSelector(state => state.venueInfo)
  const selectedStrings = useStoreSelector(state => state.selectedLanguageStrings)
  const widgetSettings = useStoreSelector(state => state.widgetSettings)
  const brandColor = widgetSettings.colorPrimary
  const venueLocale = Locale.getLocale(locale)

  const messages = useMemo(
    () => ({
      ...selectedStrings,
      [logoFooterMessages.footerLabel.id]: selectedStrings.resWidgetPoweredByLabel,
    }),
    [selectedStrings]
  )

  const themeOverride = useMemo(
    () => ({
      colors: {
        brandColor,
        venueColor,
      },
    }),
    [brandColor, venueColor]
  )

  return (
    <Root theme="gx" themeOverride={themeOverride} messages={messages} locale={venueLocale}>
      {children}
    </Root>
  )
}
