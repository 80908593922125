/* eslint-disable camelcase */
import { configureStore } from '@reduxjs/toolkit'
import camelcaseKeys from 'camelcase-keys'
import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import { EmailPreferenceCenterAdapter, type ReservationWidget, type BaseVenueApi } from '@sevenrooms/core/api'
import { FormatService } from '@sevenrooms/core/locales'

interface SelectedLanguageStringsApi {
  subscription_success_header: string
  subscription_success_body_venue: string
  subscription_success_body_group: string
  res_widget_powered_by_label: string
}

interface MarketingConfirmationWindow extends Window {
  PRELOADED: {
    widget_settings: ReservationWidget.Settings
    selected_language_strings: SelectedLanguageStringsApi
    base_venue: BaseVenueApi
    is_group_opt_in: boolean
    is_prod: boolean
    root_url: string
    full_url: string
    MEDIA_URL: string
    google_api_client_id: string
    google_places_api_key: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cookie_consent_custom_include: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    template_data: any
  }
}

function stringsToClient(data: SelectedLanguageStringsApi) {
  const selectedLanguageStrings = camelcaseKeys(data, { deep: true })
  return Object.entries(selectedLanguageStrings).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: FormatService.formatLocaleStringFromBE(value),
    }),
    { ...selectedLanguageStrings }
  )
}

declare let window: MarketingConfirmationWindow

const preloadedState = {
  venueInfo: EmailPreferenceCenterAdapter.venueToClient(window.PRELOADED.base_venue),
  widgetSettings: EmailPreferenceCenterAdapter.settingsToClient(window.PRELOADED.widget_settings),
  selectedLanguageStrings: stringsToClient(window.PRELOADED.selected_language_strings),
  isGroupOptIn: window.PRELOADED.is_group_opt_in,
}

export const store = configureStore({
  reducer: combineReducers({
    venueInfo: (state = {}) => state,
    selectedLanguageStrings: (state = {}) => state,
    widgetSettings: (state = {}) => state,
    isGroupOptIn: (state = {}) => state,
  }),
  preloadedState,
})

export const useStoreSelector: TypedUseSelectorHook<typeof preloadedState> = useSelector
