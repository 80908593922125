import { defineMessages } from '@sevenrooms/core/locales'

export const marketingConfirmationMessages = defineMessages({
  subscriptionSuccessHeader: {
    id: 'subscriptionSuccessHeader',
    defaultMessage: 'Thanks for confirming your subscription.',
  },
  subscriptionSuccessBodyVenue: {
    id: 'subscriptionSuccessBodyVenue',
    defaultMessage: 'You are subscribed to marketing communication from {venue_name}.',
  },
  subscriptionSuccessBodyGroup: {
    id: 'subscriptionSuccessBodyGroup',
    defaultMessage: 'You are subscribed to marketing communication from all our locations.',
  },
} as const)
