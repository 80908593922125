import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Flex, VStack, HStack, Box, CardSection, LogoFooter, TopNav } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { marketingConfirmationMessages } from '../marketingConfirmationMessages'
import { useStoreSelector } from '../store'

export function MarketingConfirmationContainer() {
  const { formatMessage } = useLocales()
  const { website, name, largeLogoUrl } = useStoreSelector(state => state.venueInfo)
  const isGroupOptIn = useStoreSelector(state => state.isGroupOptIn)
  const isMobile = useMaxWidthBreakpoint('m')

  return (
    <VStack justifyContent="space-between" minHeight="100vh">
      <VStack width="100%">
        <TopNav baseUrl={website} logoLabel={name} logo={largeLogoUrl} />
        <Flex width="100%" justifyContent="center" pt={isMobile ? 'l' : 'xxl'} pl="lm" pr="lm">
          <CardSection maxWidth="500px" p="lm">
            <HStack>
              <Box pr="s" pt="xs">
                <Icon name="GX-checkbox-marked-circle" size="lg" color="successText" />
              </Box>
              <VStack spacing="s">
                <Text textStyle={isMobile ? 'h2' : 'h1'}>{formatMessage(marketingConfirmationMessages.subscriptionSuccessHeader)}</Text>
                {isGroupOptIn ? (
                  <SecondaryText>{formatMessage(marketingConfirmationMessages.subscriptionSuccessBodyGroup)}</SecondaryText>
                ) : (
                  <SecondaryText>
                    {formatMessage(marketingConfirmationMessages.subscriptionSuccessBodyVenue, { venue_name: name })}
                  </SecondaryText>
                )}
              </VStack>
            </HStack>
          </CardSection>
        </Flex>
      </VStack>
      <LogoFooter />
    </VStack>
  )
}
